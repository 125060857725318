import React, { useState, useEffect, useContext } from 'react'

// Hooks
import { UserContext } from '../../hooks'

// Components
import Thumbnail from '../Elements/Thumbnail'
import Mentions from '../Elements/Mentions'
import useOrgId from '../../hooks/useOrgId'
import Tooltip from '../Elements/Tooltip'
import Modal from '../Elements/Modal'
import Button from '../Elements/Button'


// Assets
import GroupIcon from '../../assets/images/group-icon.png'

// API
import { chatService } from '../../api'
import config from '../../api/config'


// Utilities
import parse from 'html-react-parser'

function ViewThread(props) {
  const [messagesObj, setMessagesObj] = useState([])
  const [attachedFile, setAttachedFile] = useState(null)
  const [refresh, setRefresh] = useState(0)
  const [loading, setLoading] = useState(true)
  const [pagination, setPagination] = useState(null)
  // const [parentMessage, setParentMessage] = useState(null)
  const [parent, setParent] = useState(null)

  // Mentions
  const [mentions, setMentions] = useState([])
  const [textValue, setTextValue] = useState('')
  const [channelUsers, setChannelUsers] = useState([])
  const [channelProjects, setChannelProjects] = useState([])

  // Edit/Delete messages
	const [isOpenEdit, setisOpenEdit] = useState(false)
	const [newTextValue, setNewTextValue] = useState('')
	const [isOpenDelete, setisOpenDelete] = useState(false)
	const [toEditOrDelete, setToEditOrDelete] = useState({})

  // Organization
  const orgId = useOrgId()

  	// Current User
	const { currentUser } = useContext(UserContext)

  function updateScroll() {
    var element = document.getElementById("chat-section");
    element.scrollTop = element.scrollHeight;
  }

  useEffect(() => { // to update msg deleted or edited from WS
		let newObj = messagesObj.filter(msg => !props.deletedMsg.includes(msg.id)).map(item => {
			if ( item.id in props.updatedMsg) {
				item = props.updatedMsg[item.id]
			}
			return item
		})
		setMessagesObj(newObj)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.deletedMsg, props.updatedMsg])

  useEffect(() => {
    chatService.list_thread_messages(props.selectedThread, orgId)
      .then(response => {
        setMessagesObj(response.results)
        setPagination(response.next)
      })
      .then(() => {
        setLoading(false)
      })
      .then(() => {
        updateScroll()
      })
      .catch(error => {
        console.error("It was not possible to retrieve messages", error)
      })
  }, [orgId, props.selectedThread])

  useEffect(() => {

    chatService.get_message(props.selectedThread, orgId)
      .then(response => {
        // setParentMessage(response)
        setParent(
          <div className="parent-thread">
            <div className="flexer left">
              <Thumbnail user={response.created_by} />
              <div className="ml-20 remaining-width">
                <div className="flexer flexer-spaced">
                  <p className="heading is-dark line-margin-0">{response.created_by.public_name}</p>
                  <p className="text is-light line-margin-0">{response.time}</p>
                </div>
                <p className="text is-light line-margin-xs">{parse(response.message)}</p>
              </div>
            </div>
          </div>
        )
        return response
      })
      .then(message => {
        chatService.get_channel(message.channel, orgId)
          .then(response => {
            var users = []
            for (const u of response.channel_users) {
              users.push({ display: u.public_name, id: u.cognito_id })
            }
            setChannelUsers(users)

            var projects = []
            for (const c of response.cases) {
              projects.push({ display: c.reference_name, id: c.id })
            }
            setChannelProjects(projects)
          })
          .catch(error => {
            console.error("It was not possible to retrieve messages", error)
          })
      })
      .catch(error => {
        console.error("It was not possible to retrieve parent messages", error)
      })

  }, [orgId, props.selectedThread])



  // Submit new message
  function handleFileLoader() {
    var element = document.getElementById("file-loader-attachment")
    element.click()
  }

  function handleAttachment(e) {
    var filename = e.target.value.split('\\')
    filename = filename[filename.length - 1]
    setAttachedFile(<React.Fragment><i className="fa fa-file mr-20 is-light"></i> {filename}</React.Fragment>)
  }

  function replaceMentions(mentions, input_text_element) {
    mentions.sort((a, b) => b.startPos - a.startPos)

    for (const mention of mentions) {
      var subText = input_text_element.slice(mention.startPos, mention.endPos)

      if (subText !== mention.display) {
        continue
      }

      // console.log(mention.type, mention.id)
      if (mention.type === "case" && mention.id.length === 36) {
        subText = `<a href="/organization/${orgId}/project/${mention.id}"><span class="text is-dark is-bold cursor">${subText}</span></a>`
      }
      else {
        subText = `<span class="text is-dark is-bold">${subText}</span>`
      }

      var iniText = input_text_element.slice(0, mention.startPos)
      var finalText = input_text_element.slice(mention.endPos, input_text_element.length)

      input_text_element = iniText + subText + finalText
    }

    return input_text_element
  }

  function handleSubmit(e) {
    e.preventDefault()
    var input_text_element = document.getElementById('chat-input-text').value
    input_text_element = replaceMentions(mentions, input_text_element)

    var form_element = document.getElementById('chat-form')
    var form_data = new FormData(form_element)

    form_data.delete('message')
    form_data.append('message', input_text_element)

    // Add project mentions to form data
    for (const mention of mentions) {
      if (mention.type === "case") {
        form_data.append("case_mentions", mention.id)
      }
    }

    chatService.send_thread_message(form_data, orgId)
      .then(response => {
        setAttachedFile(null)
        setRefresh(refresh + 1)
        setMentions([])
      })
      .then(() => {
        updateScroll()
      })
      .catch(error => {
        console.error("There was an error sending the message", error)
      })

    form_element.reset()
    setTextValue('')
  }

  function handlePagination() {
    chatService.message_pagination(pagination)
      .then(response => {
        setMessagesObj([...messagesObj, ...response.results])
        setPagination(response.next)
      })
  }

  // Mentions

  function handleChange(e, newValue, newPlainTextValue, mentions) {
    var text = newPlainTextValue
    setTextValue(text)
  }
  function handleAddUser(id, display, startPos, endPos) {
    endPos = startPos + display.length + 1
    display = "@" + display
    setMentions([...mentions, ...[{ type: 'user', startPos, endPos, display, id }]])
  }
  function handleAddProject(id, display, startPos, endPos) {
    endPos = startPos + display.length + 1
    display = "$" + display
    setMentions([...mentions, ...[{ type: 'case', startPos, endPos, display, id }]])
  }

  function onKeyDownHandler(e) {
    if (e.keyCode === 13) {
      handleSubmit(e)
    }
  }
  const onKeyDownHandlerEdit = (e) => {
    if (e.keyCode === 13) {
      handleSubmitEdit(e)
    }
  }

// Edit or Delete a thread message

const handleSubmitEdit = (e) => {
  e.preventDefault()
  var input_text_element = document.getElementById('chat-input-text').value
  input_text_element = replaceMentions(mentions, input_text_element)

  var form_element = document.getElementById('edit-chat-form')
  var form_data = new FormData(form_element)

  form_data.delete('message')
  form_data.append('message', input_text_element)

  // Add project mentions to form data
  for (const mention of mentions) {
    if (mention.type === 'case' && mention.id.length === 36) {
      form_data.append('case_mentions', mention.id)
    }
  }

  chatService
    .edit_thread_messages(form_data, orgId, toEditOrDelete.id)
    .then((response) => {
      setAttachedFile(null)
      setRefresh(refresh + 1)
      setMentions([])
      setisOpenEdit(false)
      setToEditOrDelete({})
      
    })
    .then(() => {
      updateScroll()
    })
    .catch((error) => {
      console.error('There was an error editing the message', error)
    })

  form_element.reset()
  setNewTextValue('')
}
  
  const handleNewText = (e, newValue, newPlainTextValue, mentions) => {
    var text = e.target.value
    setNewTextValue(text)
  }
  
	const handleClick = (action, id, message) => {
    action === 'edit' && setisOpenEdit(true)
		action === 'delete' && setisOpenDelete(true)
		setToEditOrDelete({id, message})
		setNewTextValue(message)
		
	}
  const handleDeleteMsg = () => {
    chatService.delete_thread_messages( orgId, toEditOrDelete.id)
    .then(response => {
      setRefresh(refresh + 1)
      setisOpenDelete(false)
      setToEditOrDelete({})
    })
    .catch(error => {
      console.error("It was not possible to delete this message", error)
    })
  }
	

  const handleCloseEdit = () => {
    setisOpenDelete(false)
    setToEditOrDelete({})
 }
  
	const handleCloseModal = (action) => {
    action === 'edit' && setisOpenEdit(false)
		action === 'delete' && setisOpenDelete(false)
	}
  
  // Chat messages

  var messages = []
  var date = null

  for (const [index, message] of [...props.newMessage, ...messagesObj].entries()) {

    if (message.date !== date) {
      if (index !== 0) {
        messages.unshift(
          <p className="center text is-light chats__date-bubble">{date}</p>
        )
      }

      date = message.date
    }

    var name = message.created_by ? message.created_by.public_name : 'Unknown'
    var attachment = null
    if (message.attachment){
        var attachment_name = message.attachment.split('/')
        attachment_name = attachment_name[attachment_name.length - 1]

        let attachment_link = message.attachment
        if (!attachment_link.includes('//')){
            const slash = attachment_link.startsWith('/') ? '' : '/'
            attachment_link = `${config.mediaUri}${slash}${attachment_link}`
        }
        attachment = <a className="link" href={attachment_link} target="_blank" rel="noopener noreferrer">{attachment_name}</a>
    }

    messages.unshift(
      <>
      <div className="flexer left mt-20 thread-wrapper">
        <Thumbnail user={message.created_by} />
        <div className="ml-20 remaining-width">
          <div className="flexer flexer-spaced">
            <p className="heading is-dark line-margin-0">{name}</p>
            <p className="text is-light line-margin-0">{message.time}</p>
          </div>
					<div className='flexer flexer-spaced'>
					  <p className='text is-light line-margin-xs'>
						{(toEditOrDelete?.id === message.id && isOpenEdit)? null : message.message && parse(message.message) }
					  </p>
					  {((message.is_modified && message.deleted) || message.deleted) && <p className='text is-light line-margin-xs ml-10 is-bold is-blurred'>Deleted</p> }
					  {(message.is_modified && !message.deleted)&& <p className='text is-light line-margin-xs ml-10 is-bold is-blurred'>Edited</p> }
					</div>
          {attachment}
          {(toEditOrDelete?.id === message.id && isOpenEdit) ? null : 
          <div className='flexer'>
            { currentUser.cognito_id === message.created_by.cognito_id && <>
            <Tooltip content="Edit " direction="top">
              <i className='fa fa-pencil-square-o is-light cursor start-thread mr-20 ' onClick={() => handleClick('edit', message.id, message.message)}/>
            </Tooltip>
            <Tooltip content="Delete " direction="top">
              <i className='fa fa-trash is-light cursor start-thread ' onClick={() => handleClick('delete', message.id, message.message)}/>
            </Tooltip>
          </>}
          </div>}
        </div>
      </div>
      { (toEditOrDelete?.id === message.id && isOpenEdit) &&
				 <div className='' >
			 	<form
				id='edit-chat-form'
				className='simple-profile__sections flexer flexer-vcenter flexer-spaced'
				onSubmit={handleSubmitEdit}
				onKeyDown={onKeyDownHandlerEdit}
				>
				<div className='flexer-12'>
					<div className='flexer flexer-vcenter'>
						<input
							id='file-loader-attachment'
							name='attachment'
							type='file'
							className='file-input'
							onChange={handleAttachment}
						/>
						<i className='fa fa-paperclip mr-20 cursor is-dark' onClick={handleFileLoader}></i>
						<div className='remaining-width'>
							<Mentions
								textValue={newTextValue}
								handleChange={handleNewText}
								channelUsers={channelUsers}
								handleAddUser={handleAddUser}
								channelProjects={channelProjects}
								handleAddProject={handleAddProject}
							/>
							<p className='text is-light line-margin-0 left' id='attached-file'>
								{attachedFile}
							</p>
						</div>
						<input type="hidden" name="parent_message" value={props.selectedThread} />
					</div>
				</div>
				<i className='fa fa-paper-plane ml-5 is-orange cursor' onClick={handleSubmitEdit} />
				<i className='fa fa-close ml-10 cursor' onClick={handleCloseEdit} />
			</form>
			 </div> }
      </>
    )
  }

  if (date) {
    messages.unshift(
      <p className="center text is-light chats__date-bubble">{date}</p>
    )
  }

  if (pagination) {
    messages.unshift(
      <p className="center text is-light chats__date-bubble cursor mb-5" onClick={handlePagination}>Load More</p>
    )
  }



  return (
    <>
      <div className="simple-profile__sections flexer flexer-spaced flexer-vcenter">
        <div className="flexer flexer-vcenter">
          <img style={{ 'width': '40px' }} alt="profile" src={GroupIcon} />
          <p className="heading is-dark line-margin-0 ml-10">Thread</p>
        </div>
        <i className="fas fa-reply is-dark cursor" onClick={props.onReturn} />
      </div>
      <div id="chat-section" className="simple-profile__sections max-screen__bottom scroll">
        {parent}
        {loading ? <p className="text line-margin-0 is-light">Loading Messages</p> : messages}
      </div>
      <form id="chat-form" className="simple-profile__sections flexer flexer-vcenter flexer-spaced" onSubmit={handleSubmit} onKeyDown={onKeyDownHandler} >
        <div className="flexer-12">
          <div className="flexer flexer-vcenter">
            <input id="file-loader-attachment" name="attachment" type="file" className="file-input" onChange={handleAttachment} />
            <i className="fa fa-paperclip mr-20 cursor is-dark" onClick={handleFileLoader}></i>
            <div className="remaining-width">
              <Mentions
                textValue={textValue}
                handleChange={handleChange}
                channelUsers={channelUsers}
                handleAddUser={handleAddUser}
                channelProjects={channelProjects}
                handleAddProject={handleAddProject} />
              <p className="text is-light line-margin-0 left" id="attached-file">{attachedFile}</p>
            </div>
            <input type="hidden" name="parent_message" value={props.selectedThread} />
          </div>
        </div>
        <i className="fa fa-paper-plane is-orange cursor" onClick={handleSubmit}></i>
      </form>
      <Modal isOpen={isOpenDelete} handleClose={() => handleCloseModal('delete')}>
				<div className="center">
					<h1 className="title is-dark mb-30">Delete message</h1>
					<p className="text is-light mx-30">Are you sure you want to delete this message?</p>
					<Button text="Confirm" class="mt-30 one-third-width orange-btn subheading-light" type="button" onClick={handleDeleteMsg} />
				</div>
			</Modal>
    </>
  )
}

export default ViewThread