import React, { useEffect, useState, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ticketService } from 'api'
import { UserContext } from 'hooks/UserContext'
import { Button } from 'components'
import useWebSocket from 'hooks/useWebSocket'
import config from '../../api/config'

const TicketDetailContent = () => {
	const { ticket_id } = useParams()
	const { currentUser } = useContext(UserContext)

	const [data, setData] = useState()
	const [refresh, setRefresh] = useState(1)
	const [attachedFile, setAttachedFile] = useState(null)
	const [textValue, setTextValue] = useState('')

	const user_id = currentUser.cognito_id

	const profilePic =
		'https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png'

	let profile_pic = currentUser.profile.profile_pic ?? profilePic

	let status_data = {
		button_text: 'Close ticket',
		color_text: 'is-green',
		status_text: 'Opened',
	}

	if (data?.status === 'CLOSED') {
		status_data.button_text = 'Open ticket'
		status_data.color_text = 'is-red'
		status_data.status_text = 'Closed'
	}

	useEffect(() => {
		ticketService
			.retrieve(ticket_id)
			.then((response) => {
				setData(response)
			})
			.catch((error) => {
				console.error('Unable to get ticket detail', error)
			})
	}, [refresh])

	/* WEBSOCKET SECTION */
	const { WebSocketOpen, WebSocketClient } = useWebSocket('ticket', { ticket: ticket_id }) // ticket

	if (WebSocketOpen) {
		// console.log(WebSocketClient.current, 'webS detail')   /////////////////
		WebSocketClient.current.onmessage = (e) => {
			const data = JSON.parse(e.data)
			console.log(data, 'webS detail') /////////////////////
			if (data.type === 'NEW_TICKET_MESSAGE') {
				setRefresh(refresh + 1)
			}
			if (data.type === 'UPDATED_TICKET') {
				setRefresh(refresh + 1)
			}
		}
	}

	/* END WEBSOCKET SECTION */

	const handleStatus = () => {
		let new_status = data?.status === 'CLOSED' ? 'OPENED' : 'CLOSED'

		ticketService
			.update({ status: new_status }, ticket_id)
			.then((response) => {
				console.log('Status updated')
				setRefresh(refresh + 1)
			})
			.catch((error) => {
				console.error('Unable to update ticket', error)
			})
	}

	const handleFileLoader = () => {
		var element = document.getElementById('file-loader-attachment')
		element.click()
	}

	const handleAttachment = (e) => {
		let filename = e.target.value.split('\\')
		filename = filename[filename.length - 1]
		setAttachedFile(
			<>
				<i className='fa fa-file mr-10 is-light'></i> {filename}
			</>
		)
	}

	const handleCommentSubmit = (e) => {
		e.preventDefault()

		let form_element = document.getElementById('message-form')
		let form_data = new FormData(form_element)

		ticketService
			.addMessage(form_data, ticket_id)
			.then((response) => {
				console.log('Message created', form_data)
				setAttachedFile(null)
				setRefresh(refresh + 1)
			})
			.catch((error) => {
				console.error('Unable to create message', error)
			})

		form_element.reset()
		setTextValue('')
	}

	function handleChange(e, newValue, newPlainTextValue) {
		var text = newPlainTextValue
		setTextValue(text)
	}

	let commentBubble = (
		<div className='comment-bubble my-30'>
			<form className='flexer flexer-vcenter' id='message-form' onSubmit={handleCommentSubmit}>
				<img className='profile-menu__image mx-10' src={profile_pic} alt='profile' />
				<input
					id='file-loader-attachment'
					name='attachment'
					type='file'
					className='file-input'
					onChange={handleAttachment}
				/>
				<i className='fa fa fa-paperclip  is-light ml-20 cursor' onClick={handleFileLoader}></i>
				<div className='flexer remaining-width flexer-spaced flexer-vcenter'>
					<input
						name='message'
						className='flexer-11 text is-dark'
						type='text'
						placeholder='Create message...'
						onChange={handleChange}
						value={textValue}
					/>

					<div className='flexer mx-15'>
						<i className='fa fa-paper-plane  is-orange cursor' onClick={handleCommentSubmit}></i>
					</div>
				</div>
			</form>
			{attachedFile && (
				<div className='flexer'>
					<div className='flexer-1 center'></div>
					<p className='text is-light line-margin-s flexer-12 left' id='attached-file'>
						{attachedFile}
					</p>
				</div>
			)}
		</div>
	)

	let messages =
		data &&
		data.ticket_messages?.map((comment) => {
			let attachment
			if (comment.attachment) {
				let attachment_name = comment.attachment.split('/')
				attachment_name = attachment_name[attachment_name.length - 1]

				let attachment_link = comment.attachment
				if (!attachment_link.includes('//')) {
					const slash = attachment_link.startsWith('/') ? '' : '/'
					attachment_link = `${config.mediaUri}${slash}${attachment_link}`
				}
				attachment = (
					<a className='link' href={attachment_link} target='_blank' rel='noopener noreferrer'>
						{attachment_name}
					</a>
				)
			}

			return (
				<div className='mt-30' key={comment.id}>
					<div className='flexer flexer-vcenter'>
						<div className='flexer-1 left'>
							<img
								className='profile-menu__image mx-10'
								src={comment?.created_by?.profile?.profile_pic ?? profilePic}
								alt='profile'
							/>
						</div>

						<div className='flexer flexer-12 flexer-vcenter'>
							<p className='text is-dark is-blurred line-margin-0 is-bold'>
								{comment?.public_name}{' '}
								<span className='text is-light is-blurred'>left a message</span>
							</p>
							<span className='text is-grey mx-10'>•</span>
							<p className='text is-light is-blurred'>{comment.proper_created}</p>
						</div>
					</div>

					<div className='flexer flexer-vcenter content-article'>
						<div className='flexer-1 center'></div>
						<div className='flexer-12 left ticket-detail__bubble'>
							<p className='subheading is-light line-margin-xs'>{comment.message}</p>
							{attachment}
						</div>
					</div>
				</div>
			)
		})

	return (
		<>
			<div className='ticket-detail__wrapper'>
				<div className='center half-width'>
					<Link to={`/tickets/${user_id}`}>
						<p className='subheading is-orange left'>
							<i className='fa fa-angle-left is-orange mr-20'></i> Back to Tickets
						</p>
					</Link>
					<div className='flexer flexer-vcenter flexer-spaced'>
						<div>
							<p className='title'>{data?.title}</p>
						</div>
						<div>
							<Button text={status_data.button_text} class={'orange-btn'} onClick={handleStatus} />
						</div>
					</div>
					<div className='flexer flexer-vcenter mt-10'>
						<p className='text is-dark is-blurred line-margin-0'>
							Start date:{' '}
							<span className='text is-dark is-bold'>
								{data?.ticket_messages[0]?.proper_created ?? '-'}
							</span>
						</p>
						<span className='text is-grey mx-15'>•</span>
						<p className='text is-dark is-blurred line-margin-0'>
							Updated: <span className='text is-dark is-bold'>{data?.modified ?? '-'}</span>
						</p>
						<span className='text is-grey mx-15'>•</span>
						<p className='text is-dark is-blurred line-margin-0'>
							Status:{' '}
							<span className={`text ${status_data.color_text} is-bold`}>
								{status_data.status_text}
							</span>
						</p>
					</div>
				</div>
			</div>
			<div className='center half-width'>
				{commentBubble}
				{messages}
			</div>
		</>
	)
}

export default TicketDetailContent
