import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

// Components
import Button from '../../Elements/Button'
import Dropdown from '../../Elements/Dropdown'
import useOrgId from '../../../hooks/useOrgId'
import ImageContent from '../../Elements/ImageContent'
import InputField from '../../Elements/InputField'
import Tooltip from '../../Elements/Tooltip'

// API
import { accountService, accountImageService } from '../../../api'

const EditInput = (props) => {
	return (
		<fieldset
			style={{
				padding: 0,
				border: 'none',
				width: '100%',
			}}
			className={props.fieldsetClassName}
		>
			<label className='input-field__label'>{props.label}</label>
			<input
				name={props.name}
				onChange={(e) => props.onChange(e, props.name)}
				value={props.value ?? ''}
				className={`input-field__input full-width line-margin-0 ${props.inputClassName}`}
				type={props.type}
			/>
		</fieldset>
	)
}

const AddAccountForm = (props) => {
	const { account, onClose } = props
	const [newImages, setNewImages] = useState([])
	const [accountType, setAccountType] = useState(props.account.type)
	const [imagesUploaded, setImagesUploaded] = useState([])
	const [isSubmitting, setIsSubmitting] = useState(false)

	const orgId = useOrgId()

	const [inputValue, setInputValue] = useState({
		user_id: '',
		display_name: '',
		profile_page: '',
		last_active: '',
		handle: '',
		job_title: '',
		employer: '',
		note: '',
	})

	useEffect(() => {
		if (account?.id) {
			setTimeout(() => {
				setInputValue({
					user_id: account?.user_id?.content,
					display_name: account?.display_name?.content,
					profile_page: account?.profile_page?.content,
					last_active: account?.last_active?.content,
					handle: account?.handle?.content,
					job_title: account?.job_title.content,
					employer: account?.employer.content,
					note: account?.note,
				})
			}, 200)
		}
	}, [account.id])

	const hdlChangeInput = (e, field) => {
		setInputValue({ ...inputValue, [field]: e.target.value })
	}

	function handleChange(e) {
		const type_ = e.target.value
		setAccountType(type_)
		// addExtraFields(type_)
	}

	function toastMessage(type = '', accountDisplayName = '') {
		// type could be 'error' or 'success'
		return `Account ${accountDisplayName} ${type === 'error' ? 'not' : ''} saved successfully`
	}

	function handleSubmit(e) {
		e.preventDefault()

		var form_element = document.getElementById('account-form')
		var form_data = new FormData(form_element)

		var body = {}
		var last_active = {}
		var body_create = {}

		form_data.forEach((value, key) => { // update body with empty fields
			if (key.includes('last_active')) {
				last_active[key] = value
			} else if (key === 'type' || key === 'note') {
				body[key] = value
			} else if (key === 'subject') {
				body[key] = parseInt(value)
			} else {
				body[key] = { content: value }
			}
		})

		form_data.forEach((value, key) => { // create body only with values
			if (value) {
				if (key.includes('last_active')) {
					last_active[key] = value
				} else if (key === 'type' || key === 'note') {
					body_create[key] = value
				} else if (key === 'subject') {
					body_create[key] = parseInt(value)
				} else {
					body_create[key] = { content: value }
				}
			}
		})



		if (last_active) {
			if (last_active.last_active__date) {
				if (last_active.last_active__time) {
					body['last_active'] = {
						content: `${last_active.last_active__date} ${last_active.last_active__time}`,
					}
				} else {
					body['last_active'] = { content: `${last_active.last_active__date} 00:00` }
				}
			}
		}

		Object.entries(body).forEach((key) => {
			if (
				key[1]?.content === account[key[0]]?.content &&
				key[0] !== 'type' &&
				key[0] !== 'subject' &&
				key[0] !== 'note'
			) {
				// delete the key if no change
				delete body[key[0]]
			}
			if (key[0] === 'note') {
				if (key[1] === account?.note) {
					delete body.note
				}
			}
		})

		setIsSubmitting(true)
		if (props.account.id) {
			Object.keys(body)?.length >= 3 &&
				accountService
					.update(body, orgId, props.account.id)
					.then((response) => {
						setIsSubmitting(false)
						props.onClose()
						toast.success(toastMessage('success', response.display_name.content))
					})
					.catch((error) => {
						setIsSubmitting(false)
						toast.error(toastMessage('error'))
						console.log(error)
						if (Array.isArray(error)) {
							error = { non_field_errors: error[0] }
						}
					})
		} else {
			accountService
				.create(body_create, orgId)
				.then(async (response) => {
					if (imagesUploaded.length) {
						for (const img of imagesUploaded) {
							let uniqueName = crypto.randomUUID() + '-' + img.name
							const UUIDFile = new File([img], uniqueName)	
							let formData = new FormData()
							formData.append('image', UUIDFile)
							formData.append('account', response.id)
							await accountImageService.create(formData, orgId)
						}
					}
					setIsSubmitting(false)
					props.onClose()
					toast.success(toastMessage('success', response.display_name.content))
					return response
				})
				.then(() => {
					props.onClose()
				})
				.catch((error) => {
					setIsSubmitting(false)
					toast.error(toastMessage('error'))
					console.log(error)
					if (Array.isArray(error)) {
						error = { non_field_errors: error[0] }
					}
				})
		}
		Object.keys(body)?.length < 4 && onClose()
	}

	function handleFileLoader() {
		var element = document.getElementById('file-loader-image-account')
		element.click()
	}

	async function handleSubmitImage(e) {
		e.preventDefault()

		let newImagesUploaded = []

		for (const img of e.target.files) {

			let uniqueName = crypto.randomUUID() + '-' + img.name
			const UUIDFile = new File([img], uniqueName)	
			let formData = new FormData()
			formData.append('image', UUIDFile)
			formData.append('account', props.account.id)
			await accountImageService.create(formData, orgId).then((response) => {
				newImagesUploaded.push(response.image)
			})
		}

		setNewImages([...imagesUploaded, ...newImagesUploaded])
	}

	function handleChangeImg(e) {
		let newInitialImages = []
		for (let img of e.target.files) {
			newInitialImages.push(img)
		}

		setImagesUploaded([...imagesUploaded, ...newInitialImages])
	}

	function handleDelete(e) {
		let toDelete = e.currentTarget.getAttribute('name')
		let filtered = imagesUploaded.filter((files) => files.name !== toDelete)
		setImagesUploaded(filtered)
	}

	var docs = []
	if (imagesUploaded) {
		for (const doc of imagesUploaded) {
			docs.push(
				<div className='project-links flexer flexer-spaced flexer-vcenter'>
					<div className='flexer flexer-vcenter left'>
						<p className='far fa-file is-light line-margin-0'></p>
						<div className='mx-15'>
							<a
								href={doc.doc}
								className='text is-dark line-margin-0 cursor breakable'
								target='_blank'
								rel='noopener noreferrer'
							>
								{doc.name}
							</a>
							<p className='text is-light line-margin-0'>{parseInt(doc.size / 1000)} KB</p>
						</div>
					</div>
					<i className='fa fa-trash-o is-light cursor' name={doc.name} onClick={handleDelete}></i>
				</div>
			)
		}
	}

	var images_uploaded_form = (
		<React.Fragment>
			<input
				id='file-loader-image-account'
				name='image'
				type='file'
				className='file-input'
				multiple
				onChange={handleChangeImg}
			/>
			<h1 className='text is-dark is-bold mt-30'>Upload images</h1>
			<div className='flexer flexer-vcenter' onClick={handleFileLoader}>
				<div className='upload-wrapper cursor my-15'>
					<i className='fa fa-plus upload-wrapper__icon'></i>
				</div>
			</div>
			{docs.length > 0 && docs}
		</React.Fragment>
	)

	var id_input = null
	var images_form = null
	if (props.account.id) {
		id_input = <input type='hidden' name='account' value={props.account.id} />
		var images = []
		for (const image of props.account.images) {
			images.push(
				<ImageContent
					className='small-size-image one-fifth-width mr-10'
					src={image.image?.content}
					alt='photo'
				/>
			)
		}
		for (const image of newImages) {
			images.push(
				<ImageContent
					className='small-size-image one-fifth-width mr-10'
					src={image?.content}
					alt='photo'
				/>
			)
		}

		images_form = (
			<form id='images-form'>
				<input
					id='file-loader-image-account'
					name='image'
					type='file'
					className='file-input'
					multiple
					onChange={handleSubmitImage}
				/>
				{id_input}
				<h1 className='text is-dark is-bold mt-30'>Upload images</h1>
				<div className='flexer flexer-vcenter' onClick={handleFileLoader}>
					<div className='upload-wrapper cursor my-15'>
						<i className='fa fa-plus upload-wrapper__icon'></i>
					</div>
				</div>
				<p className='text is-light line-margin-0'>Uploaded</p>
				<div className='flexer x-scroll line-margin-xs'>{images}</div>
			</form>
		)
	}

	let noteField = (
		<>
			<fieldset
				style={{
					padding: 0,
					border: 'none',
					borderRadius: '5px',
					gridColumn: '1 / 3',
					position: 'relative',
				}}
			>
				<textarea
					name='note'
					onChange={(e) => hdlChangeInput(e, 'note')}
					value={inputValue?.note}
					placeholder={'Notes'}
					className={'input-field__input'}
					style={{ width: '100%', height: '100%', backgroundColor: 'none', resize: 'none' }}
				/>

				{inputValue?.note?.length > 0 && (
					<i
						style={{ cursor: 'pointer' }}
						onClick={() => {
							setInputValue({ ...inputValue, note: ' ' })
						}}
						className='fa fa-times icon input-wrapper__icon-right search-icon'
					/>
				)}
			</fieldset>
		</>
	)

	var inputFields
	if (accountType !== 'streetview') {
		inputFields = (
			<React.Fragment>
				<EditInput
					name='user_id'
					label='User ID'
					type='text'
					fieldsetClassName='my-20'
					value={inputValue?.user_id}
					onChange={hdlChangeInput}
				/>

				<EditInput
					name='display_name'
					label='Display Name'
					type='text'
					fieldsetClassName='my-20'
					value={inputValue?.display_name}
					onChange={hdlChangeInput}
				/>

				<Tooltip content='www.example.com/example' direction='bottom' class='full-width'>
					<EditInput
						name='profile_page'
						label='Profile page URL'
						type='text'
						fieldsetClassName='my-20'
						value={inputValue?.profile_page}
						onChange={hdlChangeInput}
					/>
				</Tooltip>
				<div className='flexer flexer-spaced'>
					<InputField
						name='last_active__date'
						label='Last active'
						type='date'
						class='flexer-6 line-margin-xs'
					/>
					<InputField
						name='last_active__time'
						label='Time'
						type='time'
						class='flexer-6 line-margin-xs'
					/>
				</div>
				{accountType === 'twitter' && (
					<EditInput
						name='handle'
						label='Handle'
						type='text'
						fieldsetClassName='my-20'
						value={inputValue.handle}
						onChange={hdlChangeInput}
					/>
				)}
				{accountType === 'linkedin' && (
					<>
						<EditInput
							name='job_title'
							label='Job Title'
							type='text'
							fieldsetClassName='my-20'
							value={inputValue?.job_title}
							onChange={hdlChangeInput}
						/>
						<EditInput
							name='employer'
							label='Employer'
							type='text'
							fieldsetClassName='my-20'
							value={inputValue?.employer}
							onChange={hdlChangeInput}
						/>
					</>
				)}
				{/* {extra_fields} */}
				{/* <Textarea name='note' label='Notes' /> */}
				{noteField}
			</React.Fragment>
		)
	} else {
		inputFields = (
			<React.Fragment>
				<EditInput
					name='display_name'
					label='Location'
					type='text'
					fieldsetClassName='my-20'
					value={inputValue?.display_name}
					onChange={hdlChangeInput}
				/>

				<Tooltip content='www.example.com/example' direction='bottom' class='full-width'>
					<EditInput
						name='profile_page'
						label='Street View URL'
						type='text'
						fieldsetClassName='my-20'
						value={inputValue?.profile_page}
						onChange={hdlChangeInput}
					/>
				</Tooltip>
				{/* {extra_fields} */}
				{/* <Textarea name='note' label='Notes' /> */}
				{noteField}
			</React.Fragment>
		)
	}

	return (
		<React.Fragment>
			<form id='account-form' onSubmit={handleSubmit}>
				{props.account.id ? (
					<h1 className='title is-dark mb-30'> Edit Account</h1>
				) : (
					<h1 className='title is-dark mb-30'> Add Account</h1>
				)}
				<Dropdown
					name='type'
					label='Social Media'
					type='text'
					class='line-margin-xs'
					onChange={handleChange}
				>
					<option value='facebook' selected={props.account.type === 'facebook' ? true : false}>
						Facebook
					</option>
					<option value='linkedin' selected={props.account.type === 'linkedin' ? true : false}>
						LinkedIn
					</option>
					<option value='twitter' selected={props.account.type === 'twitter' ? true : false}>
						Twitter
					</option>
					<option value='streetview' selected={props.account.type === 'streetview' ? true : false}>
						Street View
					</option>
					<option value='other' selected={props.account.type === 'other' ? true : false}>
						Other
					</option>
				</Dropdown>
				{inputFields}
				{id_input}
				<input type='hidden' name='subject' value={props.subject} />
				{!props.account.id && images_uploaded_form}
			</form>

			{images_form}

			<div className='right center-mobile'>
				<Button
					text='Cancel'
					class='mt-30 mx-15 white-btn subheading is-dark'
					type='button'
					onClick={props.onClose}
				/>
				<Button
					text='Save changes'
					class='orange-btn mt-30 subheading is-white'
					type='submit'
					form='account-form'
					loading={isSubmitting}
				/>
			</div>
		</React.Fragment>
	)
}

export default AddAccountForm
