import React, { useState, useEffect } from 'react'
import { organizationService } from 'api'
import { Button, InputField, Modal } from 'components'
import OrganizationLogo from 'assets/images/globe_international_icon.png'
import { useOrgId, useUserRole } from 'hooks'

const EditProfile = (props) => {
  const { org, onUpdate } = props
  const [isOpen, setIsOpen] = useState(false)
  const [profileImage, setProfileImage] = useState(null)

  const currentRole = useUserRole()
  const showEagIdField = currentRole === 'SUPER_ADMIN'

  const orgId = useOrgId()

  if (!profileImage && org.profile_pic) {
    setProfileImage(org.profile_pic)
  }

  useEffect(() => { }, [profileImage])

  const handleSubmit = (e) => {
    e.preventDefault();
    let form_element = document.getElementById('form');
    let form_data = new FormData(form_element);

    let uploadedProfilePic = form_data.get("profile_pic");
    if (uploadedProfilePic.size > 0) {
      const uniqueName = crypto.randomUUID() + '-' + uploadedProfilePic.name;
      form_data.set("profile_pic", uploadedProfilePic, uniqueName);
    }

    organizationService.update(form_data, orgId)
      .then((response) => {
        onUpdate('profile')
        setIsOpen(false)
        window.location.reload()
      })
      .catch(error => {
        console.log("Error updating profile", error)
      })
  }

  const handleFileLoader = () => {
    var element = document.getElementById('file-loader-profile')
    element.click()
  }

  function handleChangeImage({ target }) {
    if (target.files && target.files[0]) {
      setProfileImage(URL.createObjectURL(target.files[0]))
    }
  }

  return (
    <>
      <div className='dashboard-section'>
        <p className='text is-light is-bold left'>Edit Profile</p>
        <form id="form" onSubmit={handleSubmit}>
          <div className="flexer flexer-vcenter my-30">
            <img className="edit-org-profile__picture" src={profileImage ?? OrganizationLogo} alt="profile" />
            <input id='file-loader-profile' name="profile_pic" type="file" className="file-input" onChange={handleChangeImage} />
            <Button type="button" text="Upload photo" class="mx-15 orange-btn subheading is-white" onClick={handleFileLoader} />
          </div>
          <InputField class="left line-margin-s" name="name" label="Name" type="text" value={org.name} placeholder='Enter a name...' />
          <InputField class="left line-margin-s" name="description" label="Description" value={org.description} placeholder='Enter a description...' type="text" />
          {showEagIdField && (
            <InputField class="left line-margin-s" name="eag_id" label="EAG ID" value={org.eag_id} placeholder='Enter an EAG ID...' type="text" />
          )}
        </form>
        <div className='flexer flexer-spaced my-20'>
          <p></p>
          <Button type="button" text="Edit Profile" class="orange-btn right" icon="fa fa-pencil cursor" onClick={() => setIsOpen(true)} />
        </div>
      </div>
      <Modal isOpen={isOpen} handleClose={() => { setIsOpen(false) }}>
        <div className="center">
          <h1 className="title is-dark mb-30">Edit Organization Profile</h1>
          <p className="text is-light mx-30">Are you sure that you want to edit the organization profile?</p>
          <Button text="Confirm" class="mt-30 one-third-width orange-btn subheading-light" type="button" onClick={handleSubmit} />
        </div>
      </Modal>
    </>
  )
}

export default EditProfile
