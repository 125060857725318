import React from 'react'
// import { PermissionWrapper } from 'components'

// Components
import useOrgId from '../../hooks/useOrgId'

// API
import {docsService} from '../../api'

function AttachedDocsSection(props) {

    const orgId = useOrgId()

    function handleFileLoader() {
        var element = document.getElementById("file-loader")
        element.click()
    }

    function handleSubmit(e) {
        e.preventDefault()
        
        var form_element = document.getElementById('project-docs-form')
        var form_data = new FormData(form_element)

        docsService.create(form_data, orgId)
        .then(response => {
            props.onNew(response)
        })
        .catch(error => {
            console.log("Error uploading file", error)
        })
    }

    function handleDelete(doc_id){
        docsService.remove(orgId, doc_id)
        .then(response => {
            document.getElementById(`doc-${doc_id}`).remove()
        })
        .catch(error => {
            console.log("Error deleting file", error)
        })
    }

    var docs = []
    if(props.project.docs) { 
        for(const doc of props.project.docs) {
            var filename = doc.doc.split('/')
            filename = filename[filename.length - 1]
            docs.push(
                <div id={`doc-${doc.id}`} className="project-links flexer flexer-spaced flexer-vcenter">
                    <div className="flexer flexer-vcenter left">
                        <p className="far fa-file is-light line-margin-0"></p>
                        <div className="mx-15">
                            <a href={doc.doc} className="text is-dark line-margin-0 cursor breakable" target="_blank" rel="noopener noreferrer">{filename}</a>
                            <p className="text is-light line-margin-0">{doc.size}MB</p>
                        </div>
                    </div>
                    {!props.closed ? 
											// <PermissionWrapper requiredPermissions={['torch::edit_project_data']} checkAll={false} >
												<i className="fa fa-trash-o is-light cursor" onClick={()=>{handleDelete(doc.id)}}></i>
											// </PermissionWrapper>
										: null}
                </div>
            )
        }
    }
		
    return (
        <div className="simple-profile__sections center">
            <form id="project-docs-form" className="flexer flexer-spaced flexer-vcenter">
                <input id="file-loader" name="doc" type="file" className="file-input" onChange={handleSubmit}/>
                <input name="case" type="hidden" value={props.project.id ? props.project.id : ''} />
                <p className="subtitle is-dark is-bold">Attached Docs:</p>
                {!props.closed ?
									// <PermissionWrapper requiredPermissions={['torch::edit_project_data']} checkAll={false} >
										<p className="subheading is-orange cursor" onClick={handleFileLoader}>+ Attach File</p>
									// </PermissionWrapper>
                  : null
                }
            </form>
            {docs}
        </div>
    )
}

export default AttachedDocsSection
